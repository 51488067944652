import React, { Component } from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import styles from './ctn.module.sass'

import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemPanel,
} from 'react-accessible-accordion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Contact from '../components/contact'

class CTNPost extends Component {
   render() {
      const {
         title,
         author,
         content,
         disclaimers,
         tags,
         createdAt,
         previewText,
      } = this.props.data.contentfulBlogPost
      return (
         <Layout>
            <SEO title={title} description={previewText} article />
            <div className={['page', styles.page__post].join(' ')}>
               <div className={styles.top__information}>
                  <Link
                     to="/ctn/"
                     className="button button--link button--primary button--large button--has-icon button--has-icon_left"
                     id={styles.top_information__button}>
                     <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to All
                     Posts
                  </Link>
                  <div className={styles.information__expanded}>
                     <span className={styles.separator}>&bull;</span>
                     <p className={styles.post__time_to_read}>
                        {content.childMarkdownRemark.timeToRead} minutes
                     </p>
                  </div>
               </div>
               <div className={['page--header', styles.page__header].join(' ')}>
                  <h1 className={['page--title', styles.page__title].join(' ')}>
                     {title}
                  </h1>
                  {tags !== 'CT&N' && (
                     <p
                        className={[
                           'page--subheader',
                           styles.page__subheader,
                        ].join(' ')}>
                        Published on {createdAt} by{' '}
                        {author ? author : 'SPC Financial, Inc.'}
                     </p>
                  )}
               </div>
               <div className={['post--content', styles.content].join(' ')}>
                  <div
                     className={styles.post__content}
                     dangerouslySetInnerHTML={{
                        __html: content.childMarkdownRemark.html,
                     }}
                  />
               </div>

               {!!disclaimers && (
                  <div
                     className={[
                        'post--content',
                        'post--disclaimers',
                        styles.content,
                        styles.disclaimers,
                     ].join(' ')}>
                     <Accordion
                        allowZeroExpanded="true"
                        className={styles.accordion}>
                        <AccordionItem className={styles.accordion__item}>
                           <AccordionItemHeading>
                              <AccordionItemButton
                                 className={styles.accordion__button}>
                                 Links & Disclaimers
                              </AccordionItemButton>
                           </AccordionItemHeading>
                           <AccordionItemPanel
                              className={styles.accordion__panel}>
                              <div
                                 className={styles.post__disclaimers}
                                 dangerouslySetInnerHTML={{
                                    __html:
                                       disclaimers.childMarkdownRemark.html,
                                 }}
                              />
                           </AccordionItemPanel>
                        </AccordionItem>
                     </Accordion>
                  </div>
               )}

               {tags === 'CT&N' && (
                  <div className={styles.post_content_signup}>
                     <div className="left">
                        <h2>Stay up-to-date.</h2>
                        <p>
                           Would you like these weekly financial recaps
                           personally delivered to your email inbox?{' '}
                           <b>Sign up here:</b>
                        </p>
                     </div>
                     <div className="right">
                        <Contact page={'signup | Post: ' + title} />
                     </div>
                  </div>
               )}
            </div>
         </Layout>
      )
   }
}

CTNPost.propTypes = {
   data: propTypes.object.isRequired,
}

export default CTNPost

export const pageQuery = graphql`
   query ctnQuery($slug: String!) {
      contentfulBlogPost(slug: { eq: $slug }) {
         title
         author
         content {
            childMarkdownRemark {
               html
               timeToRead
            }
         }
         disclaimers {
            childMarkdownRemark {
               html
            }
         }
         slug
         tags
         createdAt(formatString: "MMMM D, YYYY")
      }
   }
`
